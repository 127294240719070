import '../../styles/pages/fall-winter-2024/a-champion-on-the-iracing-circuit.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';
import { Parallax, Background } from 'react-parallax';

import Hero from '../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-hero-1920x1080.jpg';
import socialBanner from "../../images/fall-winter-2024/social-banners/story4-social-1200x630.jpg";
import titelGraphic from "../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-title-380x245.svg";
import ctaBanner from '../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-cta-1920x515.jpg';

import iracingIMG1 from '../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-01-533x800.jpg';
import iracingIMG2 from '../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-01-800x533.jpg';
import iracingIMG3 from '../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-02-533x800.jpg';
import iracingIMG4 from '../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-02-800x533.jpg';
import iracingIMG5 from '../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-03-800x533.jpg';
import iracingIMG6 from '../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-01-3815x1355.jpg';
import iracingIMG7 from '../../images/fall-winter-2024/a-champion-on-the-iracing-circuit/story4-02-3815x1355.jpg';

import heroVideoMP4 from '../../video/fall-winter-2024/iracing/iracing-hero.mp4';
import heroVideoWEBM from '../../video/fall-winter-2024/iracing/iracing-hero.webm';
import heroVideoVTT from 'file-loader!../../video/fall-winter-2024/iracing/iracing-hero.vtt';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'A Champion on the iRacing Circuit';
    var pageDesc = 'Titan Esports simulation racer Logan Clampitt is known on virtual racing circuits across the globe. ';
    var slug = 'a-champion-on-the-iracing-circuit';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      {
        src: iracingIMG1,
        width: 533,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: iracingIMG2,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
      {
        src: iracingIMG3,
        width: 533,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: iracingIMG4,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
      {
        src: iracingIMG5,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt='A Champion on the iRacing Circuit' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">Titan Esports simulation racer Logan Clampitt is known on virtual racing circuits across the globe. 
</p>
            <span className="animated fadeInDown delay-1s byline">By Taylor Arrey, Photos by Matt Gush</span>
          </div>
          <div className="tint"></div>
          
          <video id="heroVideo" autoPlay="autoplay" loop muted="muted" playsInline >
            <source src={heroVideoWEBM} type="video/webm; codecs=vp9,vorbis" />
            <source src={heroVideoMP4} />
            <track kind="captions" srcLang="en" label="English" src={heroVideoVTT} />
          </video>
        </div>
        
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>

          






<figure className='align-right small align-outside'>
      <img src={iracingIMG3} alt="" />
      <figcaption>Logan Clampitt, CSUF computer science student</figcaption>
</figure>

<p>As 40 racers pull up to the starting line, Logan Clampitt takes a steadying breath and braces his hands on the wheel.</p>
<p>In the next 100 laps, he&rsquo;ll go head-to-head with top-notch drivers, clock one of the fastest lap times and take the lead just in time for the checkered flag — and he&rsquo;ll do it all in a virtual landscape.</p>



<p>&ldquo;When that green flag drops, I get in the zone and it&rsquo;s just me and the track,&rdquo; says Clampitt, a Cal State Fullerton computer science student who found that his love for racing is best suited online.</p>



<p>IRacing is the premier motorsport racing simulation that allows players to test their skills on racing circuits around the globe. Clampitt started competing online when he was 15, and is now among the highest-ranking racers in the world, earning top marks in collegiate and global competitions.</p>



<p>His car — a Camaro fitted with the blue and orange Titans logo — is a reminder of the community that stands behind him as he races toward each finish line.</p>
<p>&ldquo;I was competitive in iRacing throughout high school and community college, but I never got the same level of support that I&rsquo;ve received here at Cal State Fullerton. It makes me proud to represent the university,&rdquo; says Clampitt.</p>
<h2>A Titan Team Behind Him</h2>
<p>When Clampitt transferred to CSUF from Saddleback College, he set out to find a program that matched his passion for simulation racing.</p>


<figure className='align-left large align-outside'>
      <img src={iracingIMG5} alt="" />
      <figcaption>Logan Clampitt’s Camaro (Courtesy of iRacing)</figcaption>
</figure>

<p>That&rsquo;s when he learned about Titan Esports, an organization that provides students opportunities to build a network in the video gaming community and represent CSUF in esport competitions. Inside the Esports Lounge, located in Titan Student Union, gaming students have access to a dozen gaming workstations, accessories from Logitech and televisions for console gaming.</p>
<p>&ldquo;Logan has become a great representation for Titan Esports,&rdquo; says Chris Estrella-Ramirez, coordinator for sports clubs in Student Life and Leadership. &ldquo;He brings a lot of experience and passion into every competition, but more importantly, he cares about working with his teammates.&rdquo;</p>

<p>Established in 2019, the Titan Esports program supports more than 60 athletes who compete in regional and national tournaments for such games as League of Legends, Valorant and Overwatch.</p>
<p>&ldquo;The program is unique because it encourages students to take their interest in gaming and explore it in a community setting, where they meet others, work toward their gaming goals and compete at a high level,&rdquo; says Estrella-Ramirez.</p>

<blockquote className='align-right large align-outside'>
  <p>When that green flag drops, it’s just me and the track.<span className='left-quote'>”</span></p>
  <cite>Logan Clampitt, computer science student</cite>
</blockquote>

<p>Like many students in the program, Clampitt realized that competing with the Titan Esports logo means there&rsquo;s always a team behind him.</p>
<p>After finishing first place at the Nashville Superspeedway, he noticed that the digital screens around campus displayed his car with the message, &ldquo;Congratulations, Logan Clampitt.&rdquo;</p>
<p>&ldquo;I was excited that people were interested in iRacing, and I felt honored that my school supported me,&rdquo; says Clampitt.</p>

{/* <figure className='align-right large align-outside'>
      <img src={iracingIMG2} alt="" />
      <figcaption>Caption Needed</figcaption>
</figure> */}

<p>While racing competitively, he&rsquo;s also earned thousands of dollars in scholarships to help pay for his education.</p>

<figure className='align-right large align-outside'>
      <img src={iracingIMG4} alt="" />
      <figcaption>Logan Clampitt represents the Titan Esports team </figcaption>
</figure>

<p>Off the track, Clampitt is fueling his passion for computers and software in CSUF&rsquo;s <a className='links' href="https://www.fullerton.edu/engineering-computer-science/" title="College of Engineering and Computer Science" target="_blank">College of Engineering and Computer Science</a>. Taking such courses on high performance computing and learning from faculty experts, Clampitt has advanced his programming skills in front-end development while exploring his interest in graphic design and video editing.</p>



<p>As a simulation racer, Clampitt knows firsthand the power of sophisticated software and its ability to make a difference in peoples&rsquo; lives. It&rsquo;s what inspired him to pursue a career in software development to work with different clients to build, test, create and maintain software that helps them meet their goals.</p>

</div>
</article>
<Parallax
          bgImage={iracingIMG6}
          bgImageAlt=""
          strength={200}
          style={{margin: 0}}
          className='full-width'>
            <div className="caption">
            Logan Clampitt’s iRacing car fitted with the Titan Esports logo (Courtesy of iRacing)
            </div>
            <div className='height' />
</Parallax>
<Parallax
          bgImage={iracingIMG7}
          bgImageAlt=""
          strength={200}
          style={{margin: 0}}
          className='full-width'>
            <div className="caption">
            Logan Clampitt, professional simulation racer 
            </div>
            <div className='height' />
</Parallax>

<article className='wrap' id="copy-wrap-no-margin">

<div className='wrap small' style={{marginTop: '2em'}}>

<h2>Dominating a Digital Track</h2>
<p>As a kid, Clampitt visited the Auto Club Speedway and Phoenix Raceway with his dad and was mesmerized by the speed and art of NASCAR racing.</p>

<figure className='align-right small align-outside'>
      <img src={iracingIMG1} alt="" />
      <figcaption>Logan Clampitt, 2023 spring champion of the eNASCAR College iRacing Series
</figcaption>
</figure>

<p>When he found out that his favorite racer, Dale Earnhardt Jr., was active in iRacing, he knew he had to try it.</p>
<p>&ldquo;I fell in love with the game and I started racing everyday,&rdquo; shares Clampitt.</p>



<p>He won his first professional race as a rookie in 2016 and has been on track for success ever since. Clampitt joined the eNASCAR College iRacing Series, a professional competition for college racers from the United States and Canada. This past year, he was named the 2023 spring champion and finished second overall for the 2023-24 college series season.</p>




<p>Beyond collegiate competition, the young racer is also a two-time runner-up in the eNASCAR Coca-Cola iRacing Series, the signature contest for the top 40 simulation racers worldwide. His accomplishments have earned him several sponsorships throughout his career, including with such companies as Valvoline, Logitech and Sim Seats.</p>

<blockquote className='align-center  large align-outside'>
  <p>I’ve never received the same level of support that I’ve received here at Cal State Fullerton.<span className='left-quote'>”</span></p>
  <cite>Logan Clampitt, computer science student</cite>
</blockquote>

<p>For Clampitt, the fun is in the competition. He&rsquo;s learned that being competitive is all about practice, technique and staying sharp behind the wheel.</p>



<p>&ldquo;When it&rsquo;s time to race, it's almost like taking an exam where you're confident and focused. You can&rsquo;t focus on anything besides what&rsquo;s right in front of you.&rdquo; <span className="end-mark" /></p>




    


          
          </div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support Titan Esports</span><br />
              <a href="https://give.fullerton.edu/esports" target='_blank' rel='noreferrer'>give.fullerton.edu/esports</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 